export const DealStatus = [
    "Unknown",
    "Proposal Not Found",
    "Rejected",
    "Accepted",
    "Accept Wait",
    "Start Data Transfer",
    "Staged",
    "Sealing",
    "Active",
    "Expired",
    "Slashed",
    "Rejecting",
    "Failing",
    "Funds Ensured",
    "Check For Acceptance",
    "Validating",
    "Transferring",
    "Waiting For Data",
    "Verify Data",
    "Ensure Provider Funds",
    "Ensure Client Funds",
    "Provider Funding",
    "Client Funding",
    "Publish",
    "Publishing",
    "Error",
    "Finalizing",
    "Client Transfer Restart",
    "Provider Transfer Restart",
];

export const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
